<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
      <b-alert v-if="!signed" variant="warning" show  style="padding-top: 5px; padding-bottom: 5px; font-size: 14px;line-height:30px;margin-top: -30px;margin-right: -30px;margin-left: -30px;margin-bottom: 10px; width: 115%;">
        <uil-exclamation-triangle
          class="logo ml-1"
          style="margin-right: 5px;"
          size="15px"
        />
        <b>Contract not signed.</b> This project data is not confirmed by the client yet
        and may change.
      </b-alert>
      <b-alert v-if="completed" variant="info" show  style="padding-top: 5px; padding-bottom: 5px; font-size: 14px;line-height:30px;margin-top: -30px;margin-right: -30px;margin-left: -30px;margin-bottom: 10px; width: 115%;">
        <uil-lock
          class="logo ml-1"
          style="margin-right: 5px;"
          size="15px"
        />
        <b>Completed Project.</b> This project is complete and can no longer be edited.
      </b-alert>
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"      
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BAlert
} from 'bootstrap-vue'
import {
  UilExclamationTriangle,
  UilLock
} from "@iconscout/vue-unicons";

export default {
  components: {
    AppBreadcrumb,
    UilExclamationTriangle,
    BAlert,
    UilLock
  },
    data() {
      return {
        signed: true,
        completed : false
      };
    },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  mounted(){
    this.$root.$on('update-signed-alert', data => {
      this.signed = data
    })
    this.$root.$on('update-completed-alert', data => {
      this.completed = data
    })
  }
}
</script>

<style >
html{
  overflow: hidden;
}
</style>
