export default [
  {
    title: 'Revisions',
    route: 'Active Project Summary',
    icon: 'UilBill',
  },
  {
    title: 'Finance',
    route: 'Active Project Finance',
    icon: 'UilDollarAlt',
  },
  {
    title: 'Construction',
    route: 'Active Project Construction',
    icon: 'UilConstructor',
  },
  {
    title: 'FF&E',
    route: 'Active Project FF&E',
    icon: 'UilChair',
  },
  {
    title: 'AV / IT',
    route: 'Active Project AV / IT',
    icon: 'UilWifi',
  },
  {
    title: 'Back to Active Projects',
    href: '/active',
    icon: 'UilAngleLeft',
  },
]
