import Vue from 'vue'
import router from '../router'

// axios
import axios from 'axios'
let endpointUrl = '';

switch (window.location.hostname) {
  case 'development.clearspace.to':
    endpointUrl = process.env.VUE_APP_DEV_API_URL;
    break;
  case 'create.clearspace.to':
    endpointUrl = process.env.VUE_APP_API_URL;
    break;
  default:
    endpointUrl = process.env.VUE_APP_LOCAL_API_URL;
}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: endpointUrl,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  credentials: true,
  headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
  }

})


axiosIns.interceptors.response.use(
  response => {
    return response
  },
  error => {
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('isAdmin');
        router.push('/login')
      }
      return Promise.reject(error)
  }
)

Vue.prototype.$http = axiosIns

export default axiosIns