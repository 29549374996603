<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ name }}
            </p>
            <span class="user-status" v-if="isAdmin == 1">Admin</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/gandalf.jpeg')"
            class="badge-minimal"
            badge-variant="success"
            v-if="
              initials == 'PL' || initials == 'AL' || initials == 'SR' || initials == 'TR'
            "
          />
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/grumpy.jpg')"
            class="badge-minimal"
            badge-variant="success"
            v-else-if="initials == 'EG'"
          />
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :text="initials"
            class="badge-minimal"
            badge-variant="success"
            v-else
          />
        </template>

        <!--
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

         <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>
        -->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-if="isAdmin == 1"
          href="/admin"
          style="width: auto !important;"
        >
          <feather-icon size="16" icon="UserCheckIcon" class="mr-50" />
          <span>Admin</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" href="/compare-spec-sets">
          <uil-list-ul size="15" style="margin-right: 5px" />
          <span>Compare Specs</span>
        </b-dropdown-item>
        <b-dropdown-divider v-if="isAdmin == 1"></b-dropdown-divider>
        <b-dropdown-group v-if="isAdmin == 1" id="dropdown-group-1">
          <b-dropdown-header>
            <div style="color:#9e9e9e">Digital (Admin Only)</div>
          </b-dropdown-header>
          <b-dropdown-item link-class="d-flex align-items-center" href="/elements">
            <uil-list-ul size="15" style="margin-right: 5px" />
            <span>Elements</span>
          </b-dropdown-item>

        </b-dropdown-group>
        <b-dropdown-divider v-if="isAdmin == 1" />
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { UilListUl } from "@iconscout/vue-unicons";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSkeleton,
  BDropdownGroup,
  BDropdownHeader,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BSkeleton,
    UilListUl,
    // Navbar Components
    DarkToggler,
    BDropdownGroup,
    BDropdownHeader,
  },
  data() {
    return {
      name: "",
      initials: "",
      isAdmin: false,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logout() {
      this.$http
        .get("/logout", { headers: this.headers })
        .then((response) => {
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("isAdmin");
          this.showToast("success", "You have successfully logged out");
          this.$router.push({
            path: "/login",
          });
        })
        .catch((error) => {
          //console.log(error);
          //this.showToast('danger', error.message)
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("isAdmin");
          this.showToast("success", "You have successfully logged out");
          this.$router.push({
            path: "/login",
          });
        });
    },
    showToast(variant, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: message,
          variant,
        },
      });
    },
  },
  beforeMount() {
    this.name = localStorage.getItem("name");
    this.isAdmin = localStorage.getItem("isAdmin");
    this.initials = this.name
      .match(/(\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toUpperCase();
  },
};
</script>
